
const PartyNames = {
  JIU_HAO: 'jiuHao',
  DANG_JIAN: 'dangJian',
  DONG_XUN: 'dongXun',
  RUN_LIAN: 'runLian'
}

const NotJiuHao = {
  name: PartyNames.JIU_HAO,
  title: '9号学苑',
  type: 'notice_news',
  topicId: '30739847445348352',
  categoryId: '',
  href: 'party-notices',
  limit: 3,
  params: null,
  items: [],
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const NotDangJian = {
  name: PartyNames.DANG_JIAN,
  title: '智汇党建',
  type: 'notice_news',
  topicId: '30739853445169152',
  categoryId: '',
  href: 'party-notices',
  limit: 3,
  params: null,
  items: [],
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const NotDongXun = {
  name: PartyNames.DONG_XUN,
  title: '智汇冬训',
  type: 'notice_news',
  topicId: '30739856172646400',
  categoryId: '',
  href: 'party-notices',
  limit: 5,
  params: null,
  items: [],
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const NotRunLian = {
  name: PartyNames.RUN_LIAN,
  title: '智汇润廉',
  type: 'notice_news',
  topicId: '30739858728288256',
  categoryId: '',
  href: 'party-notices',
  limit: 5,
  params: null,
  items: [],
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const partyPreset = {
  Names: PartyNames,
  Parts: {
    [PartyNames.JIU_HAO]: NotJiuHao,
    [PartyNames.DANG_JIAN]: NotDangJian,
    [PartyNames.DONG_XUN]: NotDongXun,
    [PartyNames.RUN_LIAN]: NotRunLian
  }
}

export default partyPreset
