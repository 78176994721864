<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="noticecenter"
      space="48"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex flex-column ma-0 pa-0 transparent"
            >
              <div
                class="d-flex flex-column ma-0 pa-0 transparent"
              >
                <div
                  class="d-flex flex-row align-center mb-3"
                >
                  <span class="text-lg-h5 red--text">
                    {{ navPart.title }}
                  </span>
                  <v-spacer />
                  <v-text-field
                    v-model="notSearch.value"
                    append-icon="mdi-magnify"
                    filled
                    clear-icon="mdi-close-circle"
                    clearable
                    flat
                    dense
                    solo
                    rounded
                    hide-details="auto"
                    label=""
                    placeholder="查找关键字"
                    type="text"
                    class="ma-0 pa-0"
                    @click:append="onSearch"
                    @click:clear="clearSearch"
                    @keydown="onSearchKeyDown"
                  />
                </div>
                <v-divider />
              </div>

              <div
                class="d-flex flex-column ma-0 pa-0 transparent"
              >
                <land-notices
                  :items="notItems"
                  back-color="transpart"
                  card-color="grey lighten-4"
                  button-color="error"
                  padding="py-2"
                  @click="onNotice"
                />
                <div class="d-flex flex-row mt-4">
                  <v-pagination
                    v-model="notPagination.current"
                    :length="notPagination.length"
                    :total-visible="notPagination.totalVisible"
                    @input="changePagination"
                  />
                </div>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="hidden-sm-and-down"
          >
            <div
              v-for="(part, partIndex) in sideParts"
              :key="`part_${partIndex}`"
              class="d-flex flex-column"
            >
              <land-navigation
                :title="part.title"
                :items="part.buttons"
                size="text-lg-h5"
                :margin="`${partIndex > 0 ? 'mb-2 mt-6' : 'mb-2'}`"
                color="red--text"
                @click="changePart(part)"
              />
              <land-informs
                :items="part.items"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import cookies from '@/api/co.libs.cookies'

  import mixPartyBanner from '@/pages/mixins/party/mix.party.banner'
  import mixPartyNotices from '@/pages/mixins/party/mix.party.notices'
  export default {
    metaInfo: { title: '党建园地' },
    mixins: [mixPartyBanner, mixPartyNotices],
    data () {
      return {}
    },
    created () {
      const name = api.page.partyNotices.name
      const nav = JSON.parse(cookies.get(name))
      Object.assign(this.navPart, nav)

      this.initBannerParams({
        siteId: app.init.siteId,
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initNoticeParams({
        name: nav.name,
        type: nav.type,
        topicId: nav.topicId,
        categoryId: nav.categoryId
      })

      this.setCrumbs(nav)

      this.loadNotices()
    },
    methods: {
      changePart (part) {
        const params = {
          name: part.name,
          title: part.title,
          type: part.type,
          topicId: part.topicId,
          categoryId: part.categoryId
        }
        const name = api.page.partyNotices.name
        cookies.set(name, JSON.stringify(params))

        Object.assign(this.navPart, params)

        this.initNoticeParams({
          name: params.name,
          type: params.type,
          topicId: params.topicId,
          categoryId: params.categoryId
        })

        this.setCrumbs(params)

        this.loadNotices()
      }
    }
  }
</script>
